import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus' // 引入element-plus
import 'element-plus/theme-chalk/index.css' // 引入element-plus的样式
import zhCn from "element-plus/es/locale/lang/zh-cn"
// 修改分页器默认文字
zhCn.el.pagination.total = "总共：" + `{total}` + "条"
zhCn.el.pagination.goto = "跳转至"

import BaiduMap from "vue-baidu-map-3x"; //百度地图

// 1. 引入你需要的组件
import { Swipe, SwipeItem, Image as VanImage, Lazyload, Rate, Grid, GridItem, Overlay, Tab, Tabs, Pagination } from 'vant';

// 2. 引入组件样式
import 'vant/lib/index.css';


const app = createApp(App).use(store).use(router)

app.use(ElementPlus, { locale: zhCn, zIndex: 3000, size: 'small' })

app.use(Swipe);
app.use(SwipeItem);
app.use(VanImage);
app.use(Lazyload);
app.use(Rate);
app.use(Grid);
app.use(GridItem);
app.use(Overlay);
app.use(Tab);
app.use(Tabs);
app.use(Pagination);


app.use(BaiduMap, {
  ak: 'Lz6KrBKlpZGjqrIz0kByGdNexIEr2L7Z'
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

app.mount('#app')