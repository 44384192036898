import { createRouter, createWebHistory } from 'vue-router'

const HomeView        = ()=> import( '../views/HomeView.vue')
const LogiView        = ()=> import( '../views/LogiView.vue')
const MobileView      = ()=> import( '../views/MobileView.vue')
const IndexView       = ()=> import( '../views/home/IndexView.vue')
const AboutView       = ()=> import( '../views/home/AboutView.vue')
const RecruitView     = ()=> import( '../views/home/RecruitView.vue')
const NewsView        = ()=> import( '../views/home/NewsView.vue')
const FaqView         = ()=> import( '../views/home/FaqView.vue')
const MobileIndex     = ()=> import( '../views/h5/home/IndexView.vue')
const MobileAbout     = ()=> import( '../views/h5/home/AboutView.vue')
const MobileRecruit   = ()=> import( '../views/h5/home/RecruitView.vue')
const MobileRecruitDetail   = ()=> import( '../views/h5/home/RecruitDetail.vue')




const routes = [
  {
    path:'',
    component:HomeView,
    meta:{
      title:'CISPI 广州西次碧科技'
    },
    children:[
      {
        path:'',
        component:IndexView
      },
      {
        path:'index',
        name:'index',
        component:IndexView
      },
      {
        path:'about',
        name:'about',
        component:AboutView
      },
      {
        path:'recruit',
        name:'recruit',
        component:RecruitView
      },
      {
        path:'news',
        name:'news',
        component:NewsView
      },
      {
        path:'faq',
        name:'faq',
        component:FaqView
      }
    ]
  },
  {
    path: '/home',
    component: HomeView,
    meta: {
      title: 'CISPI 广州西次碧科技'
    },
    children:[
      {
        path:'',
        component:IndexView
      },
      {
        path:'index',
        name:'index',
        component:IndexView
      },
      {
        path:'about',
        name:'about',
        component:AboutView
      },
      {
        path:'recruit',
        name:'recruit',
        component:RecruitView
      },
      {
        path:'news',
        name:'news',
        component:NewsView
      },
      {
        path:'faq',
        name:'faq',
        component:FaqView
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LogiView
  },
  {
    path: '/h5',
    name: 'h5',
    component: MobileView,
    meta: {
      title: 'CISPI 广州西次碧科技'
    },
    children:[
      {
        path:'',
        name:'mobile_index',
        component: MobileIndex
      },
      {
        path:'home/about',
        name:'mobile_about',
        component: MobileAbout
      },
      {
        path:'home/recruit',
        name:'mobile_recruit',
        component: MobileRecruit
      },
      {
        path:'home/recruitDetail',
        name:'mobile_recruitDetail',
        component: MobileRecruitDetail
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
