<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends (
  _ResizeObserver
) {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};

export default {
  components: {},
  beforeCreate() {
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      console.log("手机"); //手机
      this.$router.push('h5');
    } else {
      console.log("电脑"); //电脑
    }
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
body,
div,
span,
header,
footer,
nav,
section,
aside,
article,
ul,
dl,
dt,
dd,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
i,
b,
textarea,
button,
input,
select,
figure,
figcaption {
  padding: 0;
  margin: 0;
  list-style: none;
  font-style: normal;
  text-decoration: none;
  border: none;
  font-family: open-sans;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}

body:focus,
div:focus,
span:focus,
header:focus,
footer:focus,
nav:focus,
section:focus,
aside:focus,
article:focus,
ul:focus,
dl:focus,
dt:focus,
dd:focus,
li:focus,
a:focus,
p:focus,
h1:focus,
h2:focus,
h3:focus,
h4:focus,
h5:focus,
h6:focus,
i:focus,
b:focus,
textarea:focus,
button:focus,
input:focus,
select:focus,
figure:focus,
figcaption:focus {
  outline: none;
}

input[type="button"],
input[type="submit"],
input[type="search"],
input[type="reset"] {
  -webkit-appearance: none;
}

textarea {
  -webkit-appearance: none;
}

body,
html,
div,
button {
  margin: 0;
  padding: 0;
}
a,
a:hover,
a:after,
a:link,
a:active {
  border: none;
  text-decoration: none;
  color: none;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
html {
  font: 12px/1.5 "Microsoft YaHei", "PingFang SC", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
}

img,
video {
  width: 100%;
  height: 100%;
  display: block;
}

a {
  text-decoration: none;
}

.fct {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fsa {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.fsb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.otw {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.otw2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.fwb {
  font-weight: bold;
}

.fs12 {
  font-size: 12px;
}

.fs14 {
  font-size: 14px;
}

.fs16 {
  font-size: 16px;
}

.fs18 {
  font-size: 18px;
}

.fs20 {
  font-size: 20px;
}

.fs24 {
  font-size: 24px;
}

.fs26 {
  font-size: 26px;
}

.fs32 {
  font-size: 32px;
}

.fs36 {
  font-size: 36px;
}

.fs48 {
  font-size: 48px;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.mt30 {
  margin-top: 30px;
}

.mb4 {
  margin-bottom: 4px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb16 {
  margin-bottom: 16px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb32{
  margin-bottom: 32px;
}

.mt20 {
  margin-top: 20px;
}

.mt16 {
  margin-top: 16px;
}

.mt40 {
  margin-top: 40px;
}

.mr35 {
  margin-right: 35px;
}

.ml10 {
  margin-left: 10px;
}

.white {
  color: #fff;
}

.btn {
  display: inline-block;
  vertical-align: middle;
  width: 240px;
  height: 60px;
  line-height: 60px;
  background: #f8ac00;
  border-radius: 2px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  transition: all 1s;
  -webkit-transition: all 1s;
}

.btn:hover {
  background: #ffc555;
}
</style>
